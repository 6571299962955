import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  PlusCircle,
} from "react-bootstrap-icons";
import { useAuth } from "../AuthContext";

export function Home() {
  const { username, isAdmin } = useAuth();
  return (
      <>
        <Row>
          <Col sm={3}></Col>
          <Col sm={6}>
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>
                  <h3>You are logged in to the Evotix Control Plane as <span style={{color: "#f63"}}>{username}</span></h3>
                </Card.Title>
                <Card.Text>
                  The Evotix Control Plane is the tool we use for managing our product platform. The Control Plane is developed and maintained by the Platform Engineering team (aka Wayfarers team). Each page within the Control Plane has a help section, please consult this for assistance and if you still need help please contact the Wayfarers team. If you find any issues or have suggestions for improvements please pass these to the Wayfarers team. 
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>
                  <h3>Assure access requests</h3>
                </Card.Title>
                <Card.Text>
                  The Control Plane provides a means for authorised members of Evotix staff to request a personal system administrator account for access to a specific customer in Assure. Access is granted on a time limited basis to a specific customer, depending on the access period requested the request may require approval.
                </Card.Text>
                <Button as={Link} to="/access-request/mine" style={{ marginBottom: "10px" }}>
                  My Requests
                </Button>{" "}
                {isAdmin ? (
                  <>
                    <Button as={Link} to="/access-request/pending" style={{ marginBottom: "10px" }}>
                      Pending Requests
                    </Button>{" "}
                  </>
                ) : null}
                <Button as={Link} to="/access-request/new" variant="success" style={{ marginBottom: "10px" }}>
                 <PlusCircle /><span style={{verticalAlign: "middle"}}> New Request</span>
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={3}></Col>
        </Row>
      </>
    );
}
