import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Pagination, Spinner, Alert } from "react-bootstrap";
import moment from "moment-timezone";
import "moment-duration-format";
import ActionModal from "../../components/access-request/ActionModal";
import { useApi } from "../../util/api";
import formatDuration from "../../util/formatDuration";
import { useAuth } from "../../AuthContext";
import { Link } from "react-router-dom";

import {
  CheckCircle,
  XCircle,
  ArrowClockwise,
  PlusCircle,
  InfoCircle,
  QuestionCircle,
} from "react-bootstrap-icons";

function RequestsComponent({
  pageTitle,
  fetchEndpoint,
  allowApproveAction,
  showRequestStatusColumn,
  showUserColumn,
  HelpCanvas,
}) {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showHelp, setShowHelp] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { isAmplifyConfigured } = useAuth();
  const { getApi, isLoading, apiError } = useApi();
  const initialLoadRef = useRef(true);

  useEffect(() => {
    if (initialLoadRef.current && isAmplifyConfigured) {
      initialLoadRef.current = false;
      fetchRequests();
    }
  }, [isAmplifyConfigured]);

  const fetchRequests = async () => {
    try {
      const { body: responseBody } = await getApi(fetchEndpoint);
      responseBody && setRequests(responseBody.reverse());
    } catch (error) {
      console.error("Error fetching requests:", error);
    }
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  const handleShowCancelModal = (request) => {
    if (
      showApproveModal === false &&
      showCancelModal === false &&
      showDetailsModal === false
    ) {
      setSelectedRequest(request);
      setShowCancelModal(true);
    }
  };

  const handleShowDetailsModal = (request) => {
    if (
      showApproveModal === false &&
      showCancelModal === false &&
      showDetailsModal === false
    ) {
      setSelectedRequest(request);
      setShowDetailsModal(true);
    }
  };

  const handleShowApproveModal = (request) => {
    if (
      showApproveModal === false &&
      showCancelModal === false &&
      showDetailsModal === false
    ) {
      setSelectedRequest(request);
      setShowApproveModal(true);
    }
  };

  const handleModalClose = ({ refreshRequests = false } = {}) => {
    setSelectedRequest(null);
    setShowApproveModal(false);
    setShowCancelModal(false);
    setShowDetailsModal(false);
    if (refreshRequests === true) {
      fetchRequests();
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(requests.length / requestsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination>
        {pageNumbers.map((number) => (
          currentPage === number ? 
            <Pagination.Item key={number} active>{number}</Pagination.Item>
          : 
            <Pagination.Item key={number} onClick={() => changePage(number)}>{number}</Pagination.Item>
        ))}
      </Pagination>
    );
  };

  const indexOfLastRequest = currentPage * requestsPerPage;
  const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
  const currentRequests = requests.slice(
    indexOfFirstRequest,
    indexOfLastRequest
  );

  return (
    <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
      <h2>{pageTitle}</h2>
      <HelpCanvas showHelp={showHelp} handleCloseHelp={handleCloseHelp}/>
      <Button onClick={fetchRequests} style={{ marginBottom: "20px" }}>
        <ArrowClockwise /> Refresh
      </Button>{" "}
      <Button
        as={Link}
        to="/access-request/new"
        style={{ marginBottom: "20px" }}
        variant="success"
      >
        <PlusCircle /><span style={{verticalAlign: "middle"}}> New Request</span>
      </Button>{" "}
      <Button
        variant="secondary"
        type="help"
        style={{ marginBottom: "20px" }}
        onClick={handleShowHelp}
      >
        <QuestionCircle /><span style={{verticalAlign: "middle"}}> Help</span>
      </Button>
      {isLoading ? (
        <h1 style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="primary" />
        </h1>
      ) : apiError ? (
        // Display error message if there is an error
        <>
          {" "}
          <Alert variant="danger">API error: {apiError}</Alert>{" "}
        </>
      ) : (
        <>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                {showUserColumn && <th>User</th>}
                <th>Request Reason</th>
                <th>Customer Key</th>
                <th>Stack</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                {showRequestStatusColumn && <th>Request Status</th>}
                <th>Total Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(currentRequests) && currentRequests.length > 0 ? (
                currentRequests.map((request, index) => (
                  <tr key={index} onClick={() => handleShowDetailsModal(request)}>
                    {showUserColumn && <td>{request.requestedBy}</td>}
                    <td>{request.requestReason}</td>
                    <td>{request.tenant}</td>
                    <td>{request.stack}</td>
                    <td>{moment(request.startTime).format("L")}</td>
                    <td>{moment(request.endTime).format("L")}</td>
                    <td>{moment(request.startTime).format("LT")}</td>
                    <td>{moment(request.endTime).format("LT")}</td>
                    {showRequestStatusColumn && (
                      <td
                        className={`${
                          request.requestStatus.toLowerCase() === "completed"
                            ? "bg-success text-white"
                            : request.requestStatus.toLowerCase() ===
                              "cancelled"
                            ? "bg-warning text-white"
                            : request.requestStatus.toLowerCase() === "failed"
                            ? "bg-danger text-white"
                            : "bg-primary text-white"
                        }`}
                      >
                        {request.requestStatus}
                      </td>
                    )}
                    <td>{formatDuration(request.totalTime)}</td>
                    <td>
                      {allowApproveAction && (
                        <Button
                          variant="success"
                          size="sm"
                          className="mt-2 me-2"
                          onClick={(e) => {e.stopPropagation(); handleShowApproveModal(request)}}
                        >
                          <CheckCircle /><span style={{verticalAlign: "middle"}}> Approve</span>
                        </Button>
                      )}
                      <Button
                        variant="danger"
                        size="sm"
                        className="mt-2 me-2"
                        onClick={(e) => {e.stopPropagation(); handleShowCancelModal(request)}}
                        // hide cancel button if request is not pending and exists (ie, not a pending request)
                        hidden={
                          request.hasOwnProperty("requestStatus") &&
                          request.requestStatus.toLowerCase() !== "pending"
                        }
                      >
                        <XCircle /><span style={{verticalAlign: "middle"}}> Cancel</span>
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="mt-2 me-2"
                        onClick={(e) => {e.stopPropagation(); handleShowDetailsModal(request)}}
                      >
                        <InfoCircle /><span style={{verticalAlign: "middle"}}> Details</span>
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                // Display "No Requests" if there are no current requests
                <tr>
                  <td colSpan="100%" style={{ textAlign: "center" }}>
                    <h2>No Requests</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {renderPagination()}
          <br />
        </>
      )}
      <>
        {selectedRequest && (
          <>
            <ActionModal
              show={showCancelModal}
              handleClose={handleModalClose}
              request={selectedRequest}
              actionType="CANCEL"
              modalTitle="Cancel Request"
              actionButtonText="Confirm Cancel"
              reasonPlaceholder="Please provide a reason for cancellation..."
            />
            <ActionModal
              show={showApproveModal}
              handleClose={handleModalClose}
              request={selectedRequest}
              actionType="APPROVE"
              modalTitle="Approve Request"
              actionButtonText="Confirm Approval"
              reasonMandatory={false}
              reasonPlaceholder="Add an optional comment..."
            />
            <ActionModal
              show={showDetailsModal}
              handleClose={handleModalClose}
              request={selectedRequest}
              actionType="DETAILS"
              modalTitle="Request Details"
              // actionButtonText="Confirm Approval"
              // reasonMandatory={false}
              // reasonPlaceholder="Add an optional comment..."
            />
          </>
        )}
      </>
    </div>
  );
}

export { RequestsComponent };
