import { useEffect } from "react";
import { useAuth } from "./AuthContext";
import { Routes, Route, useNavigate } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { Home } from "./pages/Home";
import { NewAccessRequest } from "./pages/access-request/NewAccessRequest";
import { MyRequests } from "./pages/access-request/MyRequests.js";
import { PendingRequests } from "./pages/access-request/PendingRequests";
import { Unauthenticated } from "./pages/Unauthenticated.js";

export const AppRoutes = () => {
  const { isLoggedIn, isAmplifyConfigured, postSigninPath, setPostSigninPathAndPersist } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && isAmplifyConfigured && postSigninPath) {
      console.info("Navigating to post sign in path: " + postSigninPath);
      navigate(postSigninPath);
      setPostSigninPathAndPersist("");
    }
  }, [isLoggedIn, isAmplifyConfigured, postSigninPath]);

  return (
    <>
      {isLoggedIn && isAmplifyConfigured ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/access-request/new" element={<NewAccessRequest />} />
          <Route path="/access-request/pending" element={<PendingRequests />} />
          <Route path="/access-request/mine" element={<MyRequests />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Unauthenticated />} />
        </Routes>
      )}
    </>
  );
};
