import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import { AuthProvider } from "./AuthContext";
import Navigation from "./components/Navigation.js";
import Error from "./components/Error.js";
import "./App.css";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { ConfigProvider } from "./ConfigContext.js";

function App() {
  return (
    <Router>
      <AuthProvider>
        <ConfigProvider>
          <Fragment>
            <Navigation />
            <Error error={null} />
            <Container fluid>
              <br />
              <AppRoutes />
            </Container>
          </Fragment>
        </ConfigProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
