function formatDuration(seconds) {
  const minute = 60;
  const hour = 3600;
  const day = 86400;

  // Helper function to format output
  const formatOutput = (value, unit) => {
    // Check if value is a whole number
    if (Math.floor(value) === value) {
      return `${value} ${unit}${value > 1 ? "s" : ""}`; // Pluralize if more than 1
    } else {
      return `${value.toFixed(1)} ${unit}s`; // Always pluralize for non-whole numbers
    }
  };

  // If seconds is negative, return 0 seconds
  if (seconds < 0) {
    return "0 seconds";
  }
  // Days
  else if (seconds >= day) {
    const days = seconds / day;
    return formatOutput(days, "Day");
  }
  // Hours
  else if (seconds >= hour) {
    const hours = seconds / hour;
    return formatOutput(hours, "Hour");
  }
  // Minutes
  else if (seconds >= minute) {
    const minutes = seconds / minute;
    return formatOutput(minutes, "Minute");
  }
  // Seconds
  else {
    return formatOutput(seconds, "Second");
  }
}

export default formatDuration;
