import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
        <h2>Cannot find this page</h2>
        <p>The page here cannot be found.</p>
        <p>
          Please select an option from the menu bar or <Link to="/">click here</Link>  to return to the home page.
        </p>
      </div>
    );
  }
}

export { NotFound };
