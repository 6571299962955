import React, { Component } from "react";
import AccessRequest from "../../components/access-request/AccessRequest.js";
import HelpCanvas from "../../components/HelpCanvas.js";

const HelpCanvasForPage = (props) => (
  <HelpCanvas {...props} title="Help - New Request">
    <p>
      If the requested access period is for more than 4 hours then the request
      needs to be approved.
    </p>
    <p>
      If the requested access period is for less than 4 hours BUT the total
      access period you have requested for the same customer within the 24 hour
      period spanning the end of the request is more than 8 hours, then the
      request needs to be approved.
    </p>
    <p>The maximum length of the access period is 6 months.</p>
    <p>
      Any requests for the 'nademo' stack, or for the 'supportdemo' or 'drdemo'
      customers in the 'uk' stack, will always be automatically approved.
    </p>
    <p>
      If a request requires approval you will be informed when you press submit.{" "}
      <u>There is no automated notification to approvers</u>, you will need to
      contact one of your team members who has 'admin' permissions to the
      Control Plane and ask them to approve your request.
    </p>
    <p>
      <b>
        You can only have one active access configuration per customer. This
        means that the most recently approved access request for a particular
        customer will override your previous access (if any) to the customer.
      </b>
    </p>
  </HelpCanvas>
);

class NewAccessRequest extends Component {
  render() {
    return (
      <>
        <AccessRequest HelpCanvas={HelpCanvasForPage} />
      </>
    );
  }
}

export { NewAccessRequest };
