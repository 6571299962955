import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { jwtDecode } from "jwt-decode";
import { Amplify } from "aws-amplify";
import {
  signOut as amplifySignOut,
  signInWithRedirect,
} from "aws-amplify/auth";

window.LOG_LEVEL = "DEBUG";

// Function to fetch and apply the configuration from config.json
function configureAmplify(config) {
  try {
    // Use the fetched config to configure Amplify
    Amplify.configure({
      Auth: {
        Cognito: {
          region: config.userPool.region,
          userPoolClientId: config.userPool.poolClientId,
          userPoolId: config.userPool.poolId,
          loginWith: {
            oauth: {
              domain: config.userPool.hostedDomain,
              scopes: ["email", "openid"],
              redirectSignIn: [config.userPool.redirectSignInUrl],
              redirectSignOut: [config.userPool.redirectSignOutUrl],
              responseType: "code",
            },
          },
        },
      },
      API: {
        REST: {
          ControlPlaneAPI: {
            endpoint: config.endpointUrl,
          },
        },
      },
    });
    return true;
  } catch (error) {
    console.error("Failed to configure amplify", error);
  }
  return false;
}

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [username, setUsername] = useState(null);
  const [userGroups, setUserGroups] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState(false);
  const [provider, setProvider] = useState(null);
  const [postSigninPath, setPostSigninPath] = useState(localStorage.getItem("postSigninPath"));
  const { pathname } = useLocation();

  const setAuthConfig = (config) => {
    setProvider(config.userPool.provider);
    setIsAmplifyConfigured(configureAmplify(config));
  };

  const setPostSigninPathAndPersist = (path) => {
    setPostSigninPath(path);
    localStorage.setItem("postSigninPath", path);
  };

  const setIsLoggedInAndPersist = (isLoggedIn) => {
    setIsLoggedIn(isLoggedIn);
    localStorage.setItem("isLoggedIn", isLoggedIn ? "true" : "false");
  };

  const signIn = async () => {
    console.info("Signing in with postSigninPath: " + pathname);
    setIsLoggedInAndPersist(false);
    setPostSigninPathAndPersist(pathname);
    try {
      await signInWithRedirect({
        provider: { custom: provider },
      });
    } catch (error) {
      setIsLoggedInAndPersist(false);
      setPostSigninPathAndPersist("");
      console.error("Amplify error whilst signing in: ", error);
      setError("Failed to sign in.");
    }
  };

  const signOut = async () => {
    try {
      await amplifySignOut();
    } catch (error) {
      console.error("Amplify error whilst signing out: ", error);
    }

    setUsername("");
    setUserGroups([]);
    setIsLoggedInAndPersist(false);
    setPostSigninPathAndPersist("");
    localStorage.removeItem("amplifyConfig");
};

  const getUser = async () => {
    try {
      const session = await fetchAuthSession();
      if (session.tokens) {
        const decodedToken = jwtDecode(session.tokens.idToken.toString());
        const currentUser = await getCurrentUser();
        // AWS Cognito SAML IdP usernames always have IDP name as suffix with underscore before actual username
        const username = currentUser.username.split("_", 2)[1];
        setUsername(username);        
        const groups = decodedToken["cognito:groups"] || [];
        setIsAdmin(groups.includes("admin"));
        setUserGroups(groups);
        setIsLoggedInAndPersist(true);
      } else {
        console.info("There were no tokens provided in the auth session. Signing out");
        signOut();
        setError("No tokens provided in user session.");
      }
    } catch (error) {
      console.error("Error fetching user session:", error);
      setError("Failed to fetch user session.");
    }
  };

  const getIdToken = async () => {
    try {
      const session = await fetchAuthSession();
      if (session.tokens) {
        return session.tokens.idToken.toString();
      } else {
        console.info("No user tokens in session.");
      }
    } catch (error) {
      console.error("Error fetching user session:", error);
    }

    console.info("Attempting to sign back in again to recover...");
    signIn();
    return undefined
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      console.log("A new auth event has happened:", payload);
      switch (payload.event) {
        case "signIn":
        case "signInWithRedirect":
          console.log("signed in.");
          getUser();
          break;
        case "signedOut":
          setIsLoggedInAndPersist(false);
          console.log("user have been signedOut successfully.");
          break;
        case "signIn_failure":
        case "signInWithRedirect_failure":
          setIsLoggedInAndPersist(false);
          setError("An error has occurred during the OAuth flow.");
          break;
        case "tokenRefresh":
          console.log("auth tokens have been refreshed.");
          getUser();
          break;
      }
    });

    if (isAmplifyConfigured) {
      // Attempt to fetch the user on initial load
      getUser();
      return () => unsubscribe();
    }
  }, [isAmplifyConfigured]);

  const value = {
    setAuthConfig,
    error,
    username,
    userGroups,
    isAdmin,
    isLoggedIn,
    setError,
    getIdToken,
    signIn,
    signOut,
    isAmplifyConfigured,
    postSigninPath,
    setPostSigninPathAndPersist,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
