import React from "react";
import { Offcanvas } from "react-bootstrap";

function HelpCanvas({ title, showHelp, handleCloseHelp, children }) {
  return (
    <Offcanvas show={showHelp} onHide={handleCloseHelp} scroll={true}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{title}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default HelpCanvas;
