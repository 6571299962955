import React, { useState } from "react";
import { Modal, Button, Table, Spinner } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import { useApi } from "../../util/api";
import { Checkmark } from "react-checkmark";
import { ExclamationCircle } from "react-bootstrap-icons";
import formatDuration from "../../util/formatDuration";
import moment from "moment-timezone";
import { useConfig } from "../../ConfigContext";

const ModalState = {
  FORM: "FORM",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
};

const ActionModal = ({
  show,
  handleClose,
  request,
  actionType,
  modalTitle,
  actionButtonText,
  reasonMandatory = true,
  reasonPlaceholder = "Enter your reason...",
}) => {
  const [reason, setReason] = useState("");
  const { postApi, isLoading, apiError, apiResponse } = useApi();
  const { assureDomain } = useConfig();
  const [modalState, setModalState] = useState(ModalState.FORM);

  if (!["CANCEL", "APPROVE", "DETAILS"].includes(actionType)) {
    throw new Error("Invalid actionType");
  }

  const isReasonValid = () => {
    return (
      (!reasonMandatory || reason.trim().length > 0) && modalState === "FORM"
    );
  };

  const handleInputChange = (event) => {
    setReason(event.target.value);
  };

  const handleModalClose = () => {
    handleClose(modalState !== "FORM" ? { refreshRequests: true } : {});
  };

  const handleActionConfirm = async (event) => {
    event.preventDefault();
    if (!isReasonValid()) {
      return;
    }
    setModalState("PROCESSING");
    let requestBody = {
      requestID: request.requestID,
    };

    if (reason) {
      requestBody.statusReason = reason;
    }

    const apiEndpoint =
      actionType === "CANCEL"
        ? "/access-request/cancel"
        : "/access-request/approve";
    try {
      const { statusCode } = await postApi(apiEndpoint, requestBody);
      if (statusCode === 200 || statusCode === 201) {
        setModalState("SUCCESS");
      } else {
        setModalState("FAIL");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setModalState("FAIL");
    }
  };

  const renderModalBody = () => {
    switch (modalState) {
      case ModalState.FORM:
        return (
          <>
            {actionType !== "DETAILS" ? <h5>Request Details</h5> : null}
            <Table size="sm" style={{ tableLayout: "auto", width: "auto" }}>
              <tbody>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Request Reason</th>
                  <td>{request.requestReason}</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Request ID</th>
                  <td>{request.requestID}</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Customer Key</th>
                  <td>{request.tenant}</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Stack</th>
                  <td>{request.stack}</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>User</th>
                  <td>{request.requestedBy}</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Requested Date</th>
                  <td>
                    {request.requestTime
                      ? moment(request.requestTime).format("LLLL")
                      : null}
                  </td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Start Date</th>
                  <td>
                    {request.startTime
                      ? moment(request.startTime).format("LLLL")
                      : null}
                  </td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>End Date</th>
                  <td>
                    {request.endTime
                      ? moment(request.endTime).format("LLLL")
                      : null}
                  </td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Total Time (Approx)</th>
                  <td>{formatDuration(request.totalTime)}</td>
                </tr>
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Approved By</th>
                    <td>{request.approvedBy}</td>
                  </tr>
                ) : null}
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Approved Date</th>
                    <td>
                      {request.approvedTime
                        ? moment(request.approvedTime).format("LLLL")
                        : null}
                    </td>
                  </tr>
                ) : null}
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Status</th>
                    <td>{request.requestStatus}</td>
                  </tr>
                ) : null}
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Status Reason</th>
                    <td>{request.statusReason}</td>
                  </tr>
                ) : null}
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Status Set By</th>
                    <td>{request.statusSetBy}</td>
                  </tr>
                ) : null}
                {actionType === "DETAILS" ? (
                  <tr>
                    <th style={{ whiteSpace: "nowrap" }}>Link</th>
                    <td>
                      <a
                        href={
                          "https://" +
                          request.stack +
                          "." +
                          assureDomain +
                          "/" +
                          request.tenant
                        }
                        target="_blank"
                      >
                        https://
                        {request.stack +
                          "." +
                          assureDomain +
                          "/" +
                          request.tenant}
                      </a>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
            <br />
            {actionType !== "DETAILS" ? (
              <>
                <h5>Reason</h5>
                <FormControl
                  as="textarea"
                  value={reason}
                  onChange={handleInputChange}
                  placeholder={reasonPlaceholder}
                  rows={4}
                  isInvalid={isLoading && !isReasonValid()}
                />
              </>
            ) : null}
          </>
        );
      case ModalState.PROCESSING:
        return (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Processing...</p>
          </div>
        );
      case ModalState.FAIL:
        return (
          <div className="text-center">
            <ExclamationCircle color="#db3f49" size={96} />
            <p>API error: {apiError}</p>
          </div>
        );
      case ModalState.SUCCESS:
        return (
          <div className="text-center">
            <Checkmark size="xLarge" color="#539987" />
            <p>{apiResponse ? apiResponse.message : "Success!"}</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleModalClose} backdrop={isLoading ? "static" : true} keyboard={!isLoading}>
      <Modal.Header>
        <Modal.Title>
          <h3 style={{ textAlign: "center" }}>{modalTitle}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{renderModalBody()}</>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose} disabled={isLoading}>
          Close
        </Button>
        {actionType !== "DETAILS" ? (
          <Button
            variant={actionType === "APPROVE" ? "success" : "danger"}
            onClick={handleActionConfirm}
            disabled={isLoading || !isReasonValid()}
          >
            {actionButtonText}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;
