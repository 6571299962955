import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Spinner, Form } from "react-bootstrap";
import moment from "moment-timezone";
import { useConfig } from "../../ConfigContext";
import { useApi } from "../../util/api";
import { Checkmark } from "react-checkmark";
import { ExclamationCircle, QuestionCircle } from "react-bootstrap-icons";
import Alert from "react-bootstrap/Alert";
import formatDuration from "../../util/formatDuration";
import { useNavigate } from "react-router-dom";
import ClipboardButton from "../ClipboardButton";
import Table from "react-bootstrap/Table";

// Hard Coded Tenant/Stack alert exclusions
const alertExclusions = [
  { tenant: "supportdemo", stack: "uk" },
  { tenant: "drdemo", stack: "uk" },
  { tenant: "*", stack: "nademo" },
  { tenant: "shedevops", stack: "unicorn" },
];

function AccessRequest({ HelpCanvas }) {
  const [formData, setFormData] = useState({
    requestReason: "",
    tenant: "",
    stack: "",
    startTime: moment().format("YYYY-MM-DDTHH:mm"),
    endTime: "",
  });
  const { assureStacks } = useConfig();
  const {
    postApi,
    apiError,
    apiResponse,
    apiResponseStatusCode,
    resetApi,
    isLoading,
  } = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [totalTime, setTotalTime] = useState(0);
  const navigate = useNavigate();

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  useEffect(() => {
    if (formData.startTime && formData.endTime) {
      const start = moment(formData.startTime);
      const end = moment(formData.endTime);
      const duration = end.diff(start, "hours", true);
      setTotalTime(duration);
    }
  }, [formData.startTime, formData.endTime]);

  useEffect(() => {
    let approvalAlerts = []; // Can be bypassed by exclusions
    let mandatoryAlerts = []; // Can't be bypassed by exclusions
    let approvalAllowSubmit = true;
    let mandatoryAllowSubmit = true;

    // Condition for start time in the past
    if (moment(formData.startTime) - moment().subtract(30, "minutes") < 0) {
      mandatoryAllowSubmit = false;
      mandatoryAlerts.push({
        message: "Start time cannot be more than 30 minutes in the past.",
        type: "danger",
      });
    }

    // Condition for end time before start time
    if (totalTime < 0) {
      mandatoryAllowSubmit = false;
      mandatoryAlerts.push({
        message: "Your start time must be before your end time.",
        type: "danger",
      });
    }

    // Condition for admin approval required
    if (totalTime > 4) {
      approvalAlerts.push({
        message:
          "Access requests greater than 4 hours in length require admin approval.",
        type: "info",
      });
    }

    // Condition for request duration exceeding 185 days
    if (totalTime > 4440) {
      approvalAllowSubmit = false;
      approvalAlerts.push({
        message:
          "You cannot request access for more than 185 days/6 months at a time.",
        type: "danger",
      });
    }

    // Check if the tenant/stack combination is excluded
    const isExcluded = alertExclusions.find(
      ({ tenant, stack }) =>
        (formData.tenant.toLowerCase() === tenant || tenant === "*") &&
        (formData.stack.toLowerCase() === stack || stack === "*")
    );

    // Check if the tenant has "https://" or "http://" in it and show an error message
    if (
      formData.tenant.toLowerCase().includes("https://") ||
      formData.tenant.toLowerCase().includes("http://")
    ) {
      let tenant = formData.tenant
        .substring(formData.tenant.lastIndexOf("/") + 1)
        .toLowerCase();
      let showHint = tenant
        ? !tenant.includes(".") &&
          !formData.tenant.includes("//ids.") &&
          (formData.tenant.includes("sheassure.net") ||
            formData.tenant.includes("she-development.net") ||
            formData.tenant.includes("she-experimental.net"))
        : false;
      let hint = showHint ? ` Your Customer Key may be: '${tenant}'` : "";
      mandatoryAllowSubmit = false;
      mandatoryAlerts.push({
        message: `Please ensure the Customer Key does not include 'http://' or 'https://'. If your entry is a URL like 'https://uk.sheassure.net/customername', just enter 'customername'.${hint}`,
        type: "danger",
      });
    }

    // Only display a bypass message if one of the admin checks would have been breached
    if (isExcluded && (approvalAlerts.length >= 1 || !approvalAllowSubmit)) {
      // Don't display bypass message and remove approval alerts if there are mandatory alerts
      if (mandatoryAlerts.length >= 1 || !mandatoryAllowSubmit) {
        approvalAlerts = [];
        console.log("Mandatory alerts present, removing bypass message");
      } else {
        setAllowSubmit(true);
        setAlerts([
          {
            message:
              "Bypassing admin approval, this is an excluded customer key/stack combination",
            type: "success",
          },
        ]);
        return;
      }
    }

    setAllowSubmit(approvalAllowSubmit && mandatoryAllowSubmit);
    setAlerts(mandatoryAlerts.concat(approvalAlerts));
  }, [totalTime, formData.startTime, formData.tenant, formData.stack]);

  const addDuration = (type, amount) => {
    const start = moment(formData.startTime);
    const end = start.add(amount, type).format("YYYY-MM-DDTHH:mm");
    setFormData((prevState) => ({
      ...prevState,
      endTime: end,
    }));
  };

  function resetForm() {
    setFormData({
      requestReason: "",
      tenant: "",
      stack: "",
      startTime: moment().format("YYYY-MM-DDTHH:mm"),
      endTime: "",
    });
  }

  async function handlePostSubmit(event) {
    event.preventDefault();
    setShowModal(true);

    // Trim leading and trailing spaces from tenant
    formData.tenant = formData.tenant.trim();

    try {
      const requestBody = {
        requestReason: formData.requestReason,
        tenant: formData.tenant,
        stack: formData.stack,
        startTime: moment(formData.startTime).format(),
        endTime: moment(formData.endTime).format(),
      };
      await postApi("/access-request/new", requestBody);
    } catch (error) {
      console.error("Error posting data:", error);
    }
    if (apiResponseStatusCode === 201) {
      resetForm();
    }
  }

  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  function handleCloseModal() {
    setShowModal(!showModal);
    if (apiResponseStatusCode === 201) {
      navigate("/access-request/mine");
    } else {
      resetApi();
    }
  }

  const renderModalBody = () => {
    let successBody = "";
    if (apiResponse) {
      const apiHasCredentials =
        apiResponse.username && apiResponse.password && apiResponse.url;

      // if the start time is in the future then show a message, rather than the password
      const showCopyCredentials =
        apiHasCredentials && moment(formData.startTime) - moment() > 0;

      if (showCopyCredentials) {
        successBody = (
          <>
            <br />
            <Alert variant="info">{apiResponse.message}</Alert>
            <p>
              <strong>
                <u>
                  Your account will be active from{" "}
                  {moment(formData.startTime).format("LL")} at{" "}
                  {moment(formData.startTime).format("HH:mm")}. You have been
                  sent at email with your credentials to access Assure once your
                  account becomes active.
                </u>
              </strong>
            </p>
          </>
        );
      } else if (apiHasCredentials) {
        successBody = (
          <>
            <br />
            <Alert variant="info">{apiResponse.message}</Alert>
            <Alert variant="warning">
              <p>
                As stated in our security policies, it is{" "}
                <u>strictly forbidden</u> to share these credentials with any
                other person.
              </p>
              <p>
                Do not store or copy these credentials to any other location
                (other than copying to the clipboard for immediate use to login
                to Assure).
              </p>
            </Alert>
            <Table
              size="sm"
              style={{ tableLayout: "auto", width: "auto", margin: "auto" }}
            >
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Copy to clipboard</td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Username</th>
                  <td>{apiResponse.username}</td>
                  <td>
                    <ClipboardButton textToCopy={apiResponse.username} />
                  </td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>Password</th>
                  <td>********</td>
                  <td>
                    <ClipboardButton textToCopy={apiResponse.password} />
                  </td>
                </tr>
                <tr>
                  <th style={{ whiteSpace: "nowrap" }}>URL</th>
                  <td>
                    <Button
                      variant="link"
                      href={apiResponse.url}
                      target="_blank"
                    >
                      Direct Link
                    </Button>
                  </td>
                  <td>
                    <ClipboardButton textToCopy={apiResponse.url} />
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <p style={{ fontStyle: "italic" }}>
              <strong>Note:</strong> You will receive an email containing these
              details. Once you close this window, the password will{" "}
              <strong>only</strong> be accessible through that email.
            </p>
          </>
        );
      } else {
        console.log(
          "No username, password, or url in response; showing default reply"
        );
        successBody = (
          <>
            <br />
            <Alert variant="info">{apiResponse.message}</Alert>
            <p>
              <strong>
                <u>
                  You need to contact one of your team's 'approvers' to get your
                  request approved.
                </u>
              </strong>
            </p>
          </>
        );
      }
    }

    return (
      <>
        {isLoading ? (
          <>
            <h3 style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </h3>
            <h5 style={{ textAlign: "center" }}>
              This may take a moment, please don't navigate away from this page.
            </h5>
          </>
        ) : apiError ? (
          <div className="text-center">
            <ExclamationCircle color="#db3f49" size={96} />
            <p>API error: {apiError}</p>
          </div>
        ) : apiResponseStatusCode === 201 ? (
          <div className="text-center">
            <Checkmark size="xLarge" color="#539987" />
            {successBody}
          </div>
        ) : (
          <div className="text-center">
            <ExclamationCircle color="#db3f49" size={96} />
            <p>
              Unexpected API status code {apiResponseStatusCode} and body:{" "}
              {JSON.stringify(apiResponse)}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {alerts.map((alert, index) => (
        <Alert key={index} variant={alert.type}>
          {alert.message}
        </Alert>
      ))}
      <HelpCanvas showHelp={showHelp} handleCloseHelp={handleCloseHelp} />
      <Row>
        <Col lg={2} xxl={3}></Col>
        <Col lg={8} xxl={7}>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title as="h2">Assure access request</Card.Title>
              <Card.Text>
                Use this form to request a personal system administrator account
                for access to a specific customer in Assure.
                <br />
                <b>
                  You can only have one active access configuration per
                  customer. Once approved this new access request will replace
                  your current access (if any) to the specified customer.
                </b>
                <Button
                  variant="secondary"
                  type="help"
                  className="mt-2 me-2"
                  style={{
                    float: "right",
                  }}
                  onClick={handleShowHelp}
                >
                  <QuestionCircle />
                  <span style={{ verticalAlign: "middle" }}> Help</span>
                </Button>
              </Card.Text>
              <Row>
                <Form onSubmit={handlePostSubmit}>
                  <Form.Group controlId="formRequestReason">
                    <Form.Label className="mt-2">Request Reason</Form.Label>
                    <Form.Control
                      type="textarea"
                      name="requestReason"
                      value={formData.requestReason}
                      onChange={handleChange}
                      placeholder="Enter request reason"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formTenant">
                    <Form.Label className="mt-2">Customer Key</Form.Label>
                    <Form.Control
                      type="text"
                      name="tenant"
                      value={formData.tenant}
                      onChange={handleChange}
                      placeholder="Enter customer key"
                      required
                    />
                    <Form.Text id="tenantHelpBlock" muted>
                      Not case-sensitive but ensure the spelling is correct and
                      there are no extra spaces
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formStack">
                    <Form.Label className="mt-2">Stack</Form.Label>
                    <Form.Control
                      as="select"
                      name="stack"
                      value={formData.stack}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Stack</option>
                      {assureStacks.map((stack) => (
                        <option key={stack} value={stack}>
                          {stack}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formStartTime">
                    <Form.Label className="mt-2">Start Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="startTime"
                      value={formData.startTime}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formEndTime">
                    <Form.Label className="mt-2">End Time</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="endTime"
                      value={formData.endTime}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <div>
                    {[
                      {
                        label: "+1 hour",
                        type: "hours",
                        amount: 1,
                        variant: "success",
                      },
                      {
                        label: "+2 hours",
                        type: "hours",
                        amount: 2,
                        variant: "success",
                      },
                      {
                        label: "+4 hours",
                        type: "hours",
                        amount: 4,
                        variant: "success",
                      },
                      {
                        label: "+1 day",
                        type: "days",
                        amount: 1,
                        variant: "danger",
                      },
                      {
                        label: "+1 week",
                        type: "weeks",
                        amount: 1,
                        variant: "danger",
                      },
                      {
                        label: "+2 weeks",
                        type: "weeks",
                        amount: 2,
                        variant: "danger",
                      },
                      {
                        label: "+1 month",
                        type: "month",
                        amount: 1,
                        variant: "danger",
                      },
                    ].map((btn, index) => (
                      <Button
                        key={index}
                        variant={"outline-" + btn.variant}
                        className="mt-2 me-2"
                        onClick={() => addDuration(btn.type, btn.amount)}
                      >
                        {btn.label}
                      </Button>
                    ))}
                  </div>
                  <Form.Group controlId="formTotalTime">
                    <Form.Label className="mt-4">
                      Total Access Time (Approx):
                    </Form.Label>
                    <span> {formatDuration(totalTime * 3600)}</span>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-2"
                    disabled={!allowSubmit}
                  >
                    Submit Access Request
                  </Button>
                </Form>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            <h3 style={{ textAlign: "center" }}>Response</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalBody()}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AccessRequest;
