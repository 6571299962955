import React from "react";

function Error(props) {
  return (
    props.error && (
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        {props.error}
      </div>
    )
  );
}

export default Error;
