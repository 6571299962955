import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Clipboard, ClipboardCheck } from "react-bootstrap-icons";

function ClipboardButton({ textToCopy }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setClicked(true);
        setTimeout(() => setClicked(false), 1000);
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <Button variant={clicked ? "success" : "primary"} onClick={handleClick}>
      {clicked ? <ClipboardCheck /> : <Clipboard />}
    </Button>
  );
}

export default ClipboardButton;
