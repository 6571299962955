import React, { Component } from "react";
import { RequestsComponent } from "../../components/access-request/RequestsComponent";
import HelpCanvas from "../../components/HelpCanvas.js";

const HelpCanvasForPage = (props) => (
  <HelpCanvas 
    {...props}
    title="Help - My Requests">
    <p>The table shows details of all the access requests you have made. The table is ordered by the date/time of the end of the request window which means that your most recently submitted requests may not appear at the top of the list.</p>
    <p>Requests cannot be amended, so if you make a mistake you will need to submit a new request. For requests that are pending approval you have the option to cancel the request if it is no longer required using the 'cancel' button.</p>
    <p><b>You can only have one active access configuration per customer. This means that the most recently approved access request for a particular customer will override your previous access (if any) to the customer.</b></p>
    <p>Although you can use the 'details' button to view the details of any request you cannot use this to retrieve the password. Passwords are not stored in the Control Plane, you will need to refer to the email which was sent to you when the request was approved that contains the password. As mentioned above only the most recently approved request for the specified customer will be active, so make sure you use the correct password.</p>
  </HelpCanvas>
)

class MyRequests extends Component {
  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
        <RequestsComponent
          pageTitle="My Requests"
          fetchEndpoint="/access-request/mine"
          allowApproveAction={false}
          showRequestStatusColumn={true}
          showUserColumn={false}
          HelpCanvas={HelpCanvasForPage}
        />
      </div>
    );
  }
}

export { MyRequests };
