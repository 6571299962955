import React, { Component } from "react";
import { RequestsComponent } from "../../components/access-request/RequestsComponent";
import Alert from "react-bootstrap/Alert";
import HelpCanvas from "../../components/HelpCanvas.js";

const HelpCanvasForPage = (props) => (
  <HelpCanvas 
    {...props}
    title="Help - Pending Requests">
    <p>If you have access to this page then you have the ability to approve or cancel requeusts for access to Assure. Only certain access requests require approval depending on the length of the request and whether the requesting user has already made multiple requests for the same customer/stack.</p>
    <p>In the table you will see all access requests that are pending approval, but you should only approve/cancel requests that are from members of your team. Normally team members will have contacted you to request approval, but you may approve/cancel any request that you see in the table.</p>
    <p><b>Make sure you are satisfied that a request is legitimate before approving it, particularly consider whether the length of time requested is appropriate.</b></p>
    <p>Requests cannot be amended, so if you want the requester to make a change you should cancel the request filling in the reason to explain why you are cancelling and what the requester should do differently when they make a new request.</p>
  </HelpCanvas>
)

class PendingRequests extends Component {
  render() {
    return (
      <div style={{ margin: "0 auto", maxWidth: "1200px" }}>
        <Alert variant="info">
          You will see all pending access requests in the table but you should only approve/cancel requests that are from members of your team. 
          <br/><b>Make sure you are satisfied that a request is legitimate before approving it, particularly consider whether the length of time requested is appropriate.</b>
        </Alert>
        <RequestsComponent
          pageTitle="Pending Requests"
          fetchEndpoint="/access-request/pending"
          allowApproveAction={true}
          showRequestStatusColumn={false}
          showUserColumn={true}
          HelpCanvas={HelpCanvasForPage}
        />
      </div>
    );
  }
}

export { PendingRequests };
