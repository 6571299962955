import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useAuth } from "../AuthContext";
import { Spinner } from "react-bootstrap";

export function Unauthenticated() {
  const { postSigninPath, isAmplifyConfigured } = useAuth();
  return (
    <Row>
      <Col sm={3}></Col>
      <Col sm={6}>
        <Card style={{ width: "100%" }}>
          { !isAmplifyConfigured ? (
            <Card.Body>
              <Card.Title>
                <h3 style={{ textAlign: "center" }}>
                  Loading configuration... Please wait...
                </h3>
              </Card.Title>
              <h1 style={{ textAlign: "center" }}>
                <Spinner animation="border" variant="primary" />
              </h1>
            </Card.Body>
          ) : (
            postSigninPath ? (
              <Card.Body>
                <Card.Title>
                  <h3 style={{ textAlign: "center" }}>
                    Logging you in... Please wait...
                  </h3>
                </Card.Title>
                <h1 style={{ textAlign: "center" }}>
                  <Spinner animation="border" variant="primary" />
                </h1>
              </Card.Body>
            ) : (
              <Card.Body>
                <Card.Title>
                  <h3 style={{ textAlign: "center" }}>Welcome to the Evotix Control Plane</h3>
                </Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                  If you are an Evotix staff member with access to the Control Plane please click the "Log in" button from the menu bar to proceed.
                </Card.Text>
              </Card.Body>
            )
          )}
        </Card>
      </Col>
      <Col sm={3}></Col>
    </Row>
  );
}
