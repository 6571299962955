import { useAuth } from "../AuthContext";
import { useState } from "react";
import { get, post } from "aws-amplify/api";

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiResponseStatusCode, setApiResponseStatusCode] = useState(null);
  const { getIdToken } = useAuth();

  const postApi = async (path, body = null) => {
    return await callApi("POST", path, body);
  };
  const getApi = async (path, body = null) => {
    return await callApi("GET", path, body);
  };

  const resetApi = async () => {
    setApiResponse(null);
    setApiError(null);
    setIsLoading(false);
    setApiResponseStatusCode(null);
  };

  const callApi = async (method, path, body = null) => {
    setIsLoading(true);
    setApiError(null);

    let idToken = await getIdToken();
    if (!idToken) {
      console.info("No ID token available for calling API.");
      setApiError("Session token no longer available. If an automatic re-authentication does not occur then, please sign out and sign back in.");
      setIsLoading(false);
      return;
    }
   
    const apiName = "ControlPlaneAPI";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    };
    let restOperation = null;
    let parsedBody, statusCode;

    if (method === "GET") {
      restOperation = get({
        apiName: apiName,
        path: path,
        options: {
          headers: headers,
        },
      });
    } else if (method === "POST") {
      restOperation = post({
        apiName: apiName,
        path: path,
        options: {
          headers: headers,
          body: body,
        },
      });
    } else {
      setApiError("Invalid HTTP method");
      setIsLoading(false);
      return;
    }

    try {
      // Call API
      let response = await restOperation.response;
      let responseBody = await response.body.text();
      statusCode = response.statusCode;

      try {
        parsedBody = JSON.parse(responseBody);
      } catch (parseError) {
        parsedBody = responseBody;
      }
    } catch (error) {
      console.error("Error calling API:", error.message);
      statusCode = error.statusCode || null;
      setApiError(error.message || "Error without a message");
    }
    setIsLoading(false);
    setApiResponse(parsedBody);
    setApiResponseStatusCode(statusCode);
    return { body: parsedBody, statusCode: statusCode };
  };

  return {
    getApi,
    postApi,
    callApi,
    isLoading,
    apiError,
    apiResponse,
    apiResponseStatusCode,
    resetApi,
  };
};

export default useApi;
