import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import moment from "moment-timezone";
import "moment/locale/en-gb";

const configUrl = "/config.json";
window.LOG_LEVEL = "DEBUG";

const ConfigContext = createContext();

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }) => {
  const { setAuthConfig } = useAuth();
  const [isConfigured, setIsConfigured] = useState(false);
  const [assureStacks, setAssureStacks] = useState([]);
  const [assureDomain, setAssureDomain] = useState(null);
  const [environment, setEnvironment] = useState(null);
  const [currentLocale, setCurrentLocale] = useState(moment.locale());

  useEffect(() => {
    const browserLocale = window.navigator.language;
    const britishLocales = [
      "en-gb",
      "en-au",
      "en-nz",
      "en-ie",
      "en-za",
      "en-in",
    ];
    const momentLocale = britishLocales.includes(browserLocale.toLowerCase())
      ? "en-gb"
      : "en";

    moment.locale(momentLocale);
    setCurrentLocale(moment.locale());
  }, [currentLocale]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(configUrl);
        const config = await response.json();
        localStorage.setItem("configJSON", JSON.stringify(config));
      } catch (error) {
        console.error(
          "Failed to load remote configuration from /config.json: ",
          error
        );
      }

      try {
        const configJSON = localStorage.getItem("configJSON");
        if (configJSON) {
          const config = JSON.parse(configJSON);
          setAssureStacks(config.assureStacks);
          setAssureDomain(config.assureDomain);
          setAuthConfig(config);
          setIsConfigured(true);

          // Set Environment
          const url = config.endpointUrl;
          if (url.includes("sheassure.net")) {
            setEnvironment("Production");
          } else if (url.includes("she-development.net")) {
            setEnvironment("Development");
          } else if (url.includes("she-experimental.net")) {
            setEnvironment("Experimental");
          } else {
            setEnvironment(null);
          }
        } else {
          throw new Error("No config found in localStorage");
        }
      } catch (error) {
        console.error("Failed to load local configuration: ", error);
      }
    }
    fetchData();
  }, []);

  const value = {
    isConfigured,
    assureStacks,
    assureDomain,
    environment,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};
