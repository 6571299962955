import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useConfig } from "../ConfigContext";
import { useAuth } from "../AuthContext";

function Navigation() {
  const { username, isAdmin, isLoggedIn, isAmplifyConfigured, postSigninPath, signOut, signIn } = useAuth();
  const { environment } = useConfig();
  return (
    <Navbar expand="lg" variant="dark" style={{ backgroundColor: "#102B61" }}>
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          className="d-flex flex-column align-items-center"
          style={{ padding: "0px 0" }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="Logo"
            style={{ height: "38px", width: "auto", paddingBottom: "0px" }}
            className="logo-white"
          />
          <span
            style={{
              fontSize: "12px",
              color: environment === "Production" ? "white" : "#FB7B54",
              marginTop: "0px",
            }}
          >
            {environment} Control Plane
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {postSigninPath ? (
          <>
            <Navbar.Collapse>
              <Nav className="justify-content-end" style={{ width: "100%" }}>
                <Navbar.Text>
                  <span
                   style={{
                    paddingRight: "10px"
                  }}
                  >Logging in...</span>
                </Navbar.Text>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : (       
          isLoggedIn ? (
            <>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/">
                    Home
                  </Nav.Link>
                  <NavDropdown title="Access Requests" id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/access-request/mine">
                      My Requests
                    </NavDropdown.Item>
                    {isAdmin ? (
                      <NavDropdown.Item as={Link} to="/access-request/pending">
                        Pending Requests
                      </NavDropdown.Item>
                    ) : null}
                    <NavDropdown.Item as={Link} to="/access-request/new">
                      New Request
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse>
                <Nav className="justify-content-end" style={{ width: "100%" }}>
                  <Navbar.Text>
                    <span
                    style={{
                      paddingRight: "10px"
                    }}
                    >{username}</span>
                  </Navbar.Text>
                  <Button
                    style={{
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                    variant="danger"
                    onClick={() => signOut()}
                  >
                    Log out
                  </Button>
                </Nav>
              </Navbar.Collapse>
            </>
          ) : (
            <>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/">
                    Home
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Navbar.Collapse>
                <Nav className="justify-content-end" style={{ width: "100%" }}>
                  <Button
                    style={{
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "5px",
                    }}
                    variant="danger"
                    disabled = {!isAmplifyConfigured}
                    onClick={() => signIn()}
                  >
                    Log in
                  </Button>
                </Nav>
              </Navbar.Collapse>
            </>
          )
        )}
      </Container>
    </Navbar>
  );
}

export default Navigation;
